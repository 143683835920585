<template>
  <div class="page">
    <div class="banner">
      <b-carousel
        id="carousel-fade"
        style="text-shadow: 0px 0px 2px #000"
        indicators
        fade
        :interval="3000"
      >
        <b-carousel-slide v-for="(item, i) in banners" :key="i">
          <template #img>
            <img class="img" :src="$host + item.image" />
          </template>
        </b-carousel-slide>
        <!-- <b-carousel-slide>
          <template #img>
            <img class="img" src="../../static/become/banner2.jpg" />
          </template>
        </b-carousel-slide> -->
      </b-carousel>
    </div>
    <div class="content">
      <div class="view">
        <div class="title-view">
          <div class="left-icon"></div>
          皮肤管理
          <div class="left-icon"></div>
        </div>
        <div class="show">
          <div class="left-view">
            <img class="left-img" src="../../static/about/about-us_img3.png" />
          </div>
          <div class="right-view">
            <img
              class="right-img margin-b10"
              src="../../static/about/about-us_img3.png"
            />
            <img class="right-img" src="../../static/about/about-us_img3.png" />
          </div>
        </div>
      </div>
      <div class="view">
        <div class="title-view">
          <div class="left-icon"></div>
          项目展示
          <div class="left-icon"></div>
        </div>
        <div class="show">
          <div class="select-view">
            <div
              @click="selectItem(0)"
              class="item item-tree"
              :class="select == 0 ? 'item-active' : ''"
            >
              私人定制皮肤管理
            </div>
            <div
              @click="selectItem(1)"
              class="item item-tree"
              :class="select == 1 ? 'item-active' : ''"
            >
              面部皮肤管理
            </div>
            <div
              @click="selectItem(2)"
              class="item item-tree"
              :class="select == 2 ? 'item-active' : ''"
            >
              身材管理
            </div>
            <div
              @click="selectItem(3)"
              class="item"
              :class="select == 3 ? 'item-active' : ''"
            >
              抗衰
            </div>
          </div>
          <div class="show-view">
            <img class="show-view-img" src="../../static/about/about-us_img3.png" />
          </div>
        </div>
      </div>
      <div class="view">
        <div class="title-view">
          <div class="left-icon"></div>
          金牌导师
          <div class="left-icon"></div>
        </div>
        <div class="grils">
          <div class="gril">
            <img class="gril-img" src="../../static/community/img_girl.png" />
          </div>
          <div class="gril">
            <img class="gril-img" src="../../static/community/img_girl.png" />
          </div>
          <div class="gril">
            <img class="gril-img" src="../../static/community/img_girl.png" />
          </div>
          <div class="gril">
            <img class="gril-img" src="../../static/community/img_girl.png" />
          </div>
        </div>
      </div>

      <div class="view">
        <div class="title-view">
          <div class="left-icon"></div>
          培训风采
          <div class="left-icon"></div>
        </div>
        <div class="show">
          <img class="peixun-img" src="../../static/doctor/fm3.png" />
          <img class="peixun-img" src="../../static/doctor/fm3.png" />
          <img class="peixun-img" src="../../static/doctor/fm3.png" />
          <img class="peixun-img" src="../../static/doctor/fm3.png" />
        </div>
      </div>
      <div class="view">
        <div class="title-view">
          <div class="left-icon"></div>
          门店展示
          <div class="left-icon"></div>
        </div>

        <b-carousel
          id="carousel-fade"
          style="text-shadow: 0px 0px 2px #000; height: 520px"
          fade
          indicators
          controls
          label-next=""
          label-prev=""
        >
          <b-carousel-slide style="width:1200px;height: 520px" v-for="(item, index) in list" :key="index"
            :caption="item.store_name"
            :img-src="$host+item.image"
          ></b-carousel-slide>
        </b-carousel>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      select: 0,
      params: {
        page: 1,
        limit: 5,
        position: "index",
      },
      banners: [],
      list: [],
    };
  },
  components: {},
  methods: {
    selectItem(i) {
      this.select = i;
    },
    getBanner() {
      this.$http.getbannerlist(this.params).then((res) => {
        let { data, code, msg } = res;
        if (code == 1) {
          this.banners = data.items;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
    getList() {
      this.$http.getstorelist(this.params).then((res) => {
        let { msg, code, data } = res;
        if (code == 1) {
          this.list = data.items;
        } else {
          this.$store.commit("showToast", msg);
        }
      });
    },
  },
  mounted() {
    this.getBanner();
    this.getList();
  },
};
</script>
<style scoped lang="scss">
.page {
  width: 100%;
}
.content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.view {
  width: 1200px;
  margin: 20px 0;
  .title-view {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    height: 56px;
    color: #333;
    .left-icon {
      width: 120px;
      height: 5px;
      background: #fe6039;
      opacity: 0.75;
      border-radius: 2.5px;
      margin: 0 20px;
    }
  }

  .show {
    display: flex;
    margin: 10px 0;
    .left-view {
      width: 750px;
      .left-img {
        width: 750px;
        height: 430px;
      }
    }
    .right-view {
      width: 440px;
      margin-left: 10px;
      .right-img {
        width: 440px;
        height: 210px;
      }
      .margin-b10 {
        margin-bottom: 10px;
      }
    }

    .select-view {
      width: 290px;
      margin-right: 10px;
      .item {
        height: 100px;
        border: 1px solid #c0c0c0;
        border-radius: 5px;
        line-height: 100px;
        cursor: pointer;
        text-align: center;
      }
      .item-tree {
        margin-bottom: 10px;
      }
      .item-active {
        background: #fbc2ae;
        color: #fff;
        border-color: #fff;
      }
    }
    .show-view {
      width: 900px;
      .show-view-img {
        width: 900px;
        height: 430px;
      }
    }
  }

  .grils {
    display: flex;
    margin: 10px 0;
    justify-content: space-between;
    .gril {
      width: 275px;
      height: 350px;
      border: 2px solid #f6f4f4;
      text-align: center;
      .gril-img {
        width: 225px;
        // height: 300px;
      }
    }
  }

  .peixun-img {
    width: 200px;
    margin: 10px 50px;
  }
}
.banner {
  height: 700px;
  .img {
    width: 100%;
    height: 700px;
    object-fit: cover;
  }
}

.swiper {
  display: flex;
  overflow: hidden;
  .swiper-slider {
    width: 360px;
    height: 360px;
    margin: 0 20px;
    // background: rgba(0,0,0,.5);
    .swiper-img {
      width: 360px;
      height: 360px;
      z-index: 99;
    }
  }
  .shadow {
    width: 600px;
    height: 400px;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    margin-left: 300px;
  }
}
</style>
